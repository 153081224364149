var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "addComplain bgFFF commonF padding20 paddingLR20 paddingT20",
    },
    [
      _c(
        "titleBox",
        { attrs: { title: "客诉信息" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "label-position": "right",
                model: _vm.formInline,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客诉来源", prop: "dataSource" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.dataSourceChange },
                      model: {
                        value: _vm.formInline.dataSource,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "dataSource", $$v)
                        },
                        expression: "formInline.dataSource",
                      },
                    },
                    _vm._l(_vm.customerDataSourceList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.desc, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Work_Order_Number"),
                    prop: "orderNo",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入内容", maxlength: "50" },
                    model: {
                      value: _vm.formInline.orderNo,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "orderNo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.orderNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Work_order_type"),
                    prop: "orderType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formInline.orderType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "orderType", $$v)
                        },
                        expression: "formInline.orderType",
                      },
                    },
                    _vm._l(_vm.orderTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.desc, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "被反映单位", prop: "questionCompany" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", placeholder: "请输入内容" },
                    model: {
                      value: _vm.formInline.questionCompany,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "questionCompany",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.questionCompany",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "承办单位", prop: "executeCompany" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", placeholder: "请输入内容" },
                    model: {
                      value: _vm.formInline.executeCompany,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "executeCompany",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.executeCompany",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人姓名", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "20" },
                    model: {
                      value: _vm.formInline.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "20" },
                    model: {
                      value: _vm.formInline.phone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "phone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否重复投诉", prop: "repeat" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formInline.repeat,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "repeat", $$v)
                        },
                        expression: "formInline.repeat",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.formInline.dataSource === 1 ||
                        _vm.formInline.dataSource === 2,
                      expression:
                        "formInline.dataSource === 1 || formInline.dataSource === 2",
                    },
                  ],
                  attrs: { label: "平台派单时间", prop: "platformTime" },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: { change: _vm.platformTimeChange },
                    model: {
                      value: _vm.formInline.platformTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "platformTime", $$v)
                      },
                      expression: "formInline.platformTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formInline.dataSource === 2,
                      expression: "formInline.dataSource === 2",
                    },
                  ],
                  attrs: { label: "城管委派单时间", prop: "urbanTime" },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.formInline.urbanTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "urbanTime", $$v)
                      },
                      expression: "formInline.urbanTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "提醒时间", prop: "remindTime" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOptions,
                    },
                    model: {
                      value: _vm.formInline.remindTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "remindTime", $$v)
                      },
                      expression: "formInline.remindTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.formInline.dataSource === 1 ||
                        _vm.formInline.dataSource === 2,
                      expression:
                        "formInline.dataSource === 1 || formInline.dataSource === 2",
                    },
                  ],
                  attrs: { label: "截止时间", prop: "cutoffTime" },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOptions,
                    },
                    model: {
                      value: _vm.formInline.cutoffTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "cutoffTime", $$v)
                      },
                      expression: "formInline.cutoffTime",
                    },
                  }),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                { attrs: { label: "投诉具体内容", prop: "content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入内容",
                      maxlength: "500",
                    },
                    model: {
                      value: _vm.formInline.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "content", $$v)
                      },
                      expression: "formInline.content",
                    },
                  }),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.save("/complainManage")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.preservation")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("保存并处理")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }